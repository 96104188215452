<template>
<v-container>
  <v-row>
    <v-checkbox v-model="completedOnly" label="Completed Only" />
  </v-row>
  <v-row>
    <v-col>
      All Time | This Month | This Week<br>
      Completed | In Progress | unstarted
      <br><br>
      <span v-for="g, i of fgames" :key="i">
        {{g.state}} - {{g.league.name}} <img width="124" v-if="g.league.image" :src="g.league.image" /><br>
        {{g.blockName}} - {{g.startTime}}<br>
        <span v-if="g.match">{{g.match.strategy.type}} - {{g.match.strategy.count}}<br>
        {{g.match.teams[0].result.gameWins}} - {{g.match.teams[0].name}} <img width="124" :src="g.match.teams[0].image" />
         VS
         <img width="124" :src="g.match.teams[1].image" /> {{g.match.teams[1].name}} - {{g.match.teams[1].result ? g.match.teams[1].result.gameWins : 0}}
        <br>
        </span>
        <hr>
      </span>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
  import {
    mapGetters,
    mapState,
  } from 'vuex'
  export default {
    name: 'Esports',
    components: {
    },
    data () {
      return {
        completedOnly: false,
        games: {},
        leagues: ['lec', 'cblol-brazil', 'cblol_academy', 'lcs'],
      }
    },
    computed: {
      fgames () {
        var ret = []
        for (var i in this.games) {
          var g = this.games[i]
          var completed = g.state === 'completed'
          if ((!this.completedOnly ? completed : !completed) &&
            this.leagues.includes(g.league.slug)) {
            ret.push(g)
          }
        }
        if (!this.completedOnly) { // ret
          ret = ret.reverse()
        }
        return ret
      },
      ...mapState('platform', ['mainHost']),
      ...mapGetters('node', ['node']),
    },
    async mounted () {
      var ret = (await this.$http.get(this.mainHost + '/lol_esports')).data.games
      this.games = ret
    },
    methods: {
      gotoHash (hash) {
        this.$router.push('/result/' + hash)
      },
    },
  }
</script>
